define("ember-g-recaptcha/components/g-recaptcha", ["exports", "@ember/component", "@ember/object", "ember-cached-decorator-polyfill", "@ember/application", "@ember/utils", "@ember/object/internals", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _emberCachedDecoratorPolyfill, _application, _utils, _internals, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='g-recaptcha'
    ...attributes
    {{did-insert this._initialize}}
    {{will-destroy this._destroy}}
  ></div>
  */
  {
    "id": "dJ5N7YDy",
    "block": "[[[11,0],[24,0,\"g-recaptcha\"],[17,1],[4,[38,0],[[30,0,[\"_initialize\"]]],null],[4,[38,1],[[30,0,[\"_destroy\"]]],null],[12],[13]],[\"&attrs\"],false,[\"did-insert\",\"will-destroy\"]]",
    "moduleName": "ember-g-recaptcha/components/g-recaptcha.hbs",
    "isStrictMode": false
  });
  /* globals grecaptcha */
  let GRecaptchaComponent = _exports.default = (_class = class GRecaptchaComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "elementId", (0, _internals.guidFor)(this));
    }
    get config() {
      const _config = (0, _application.getOwner)(this).resolveRegistration('config:environment') || {};
      return _config['ember-g-recaptcha'] || {};
    }
    get componentOptions() {
      const defaults = ['sitekey', 'theme', 'size', 'tabindex', 'badge', 'isolated', 'skip'];
      const options = {};
      defaults.forEach(option => {
        if ((0, _utils.isPresent)((0, _object.get)(this.args, option))) {
          options[option] = (0, _object.get)(this.args, option);
        }
      });
      return options;
    }
    get options() {
      return Object.assign({}, this.config, this.componentOptions);
    }
    reset() {
      if ((0, _utils.isPresent)(this.widgetId)) {
        grecaptcha.reset(this.widgetId);
      }
    }
    _initialize(element) {
      const globalName = `__ember_g_recaptcha_${this.elementId}_onload`;
      window[globalName] = () => {
        this._render(element);
      };
      let baseUrl = [`${this.config['jsUrl'] || 'https://www.google.com/recaptcha/api.js'}?render=explicit`, `onload=${globalName}`];
      if (this.config['hl']) {
        baseUrl.push(`hl=${this.config['hl']}`);
      }
      if (!this.options['skip']) {
        this._appendScript(baseUrl.join('&'));
      } else {
        this._render();
      }
    }
    _destroy() {
      window[`__ember_g_recaptcha_${this.elementId}_onload`] = () => {};
    }
    _appendScript(src) {
      let scriptTag = document.createElement('script');
      scriptTag.src = src;
      scriptTag.async = true;
      scriptTag.defer = true;
      document.body.appendChild(scriptTag);
    }
    _render(element) {
      const parameters = Object.assign(this.options, {
        callback: this._onSuccessCallback.bind(this),
        'expired-callback': this._onExpiredCallback.bind(this),
        'error-callback': this._onErrorCallback.bind(this)
      });
      if (this.options['skip']) {
        window.grecaptcha = {
          execute: () => {
            this._onSuccessCallback();
          },
          getResponse: () => {
            return window.btoa(Date.now().toString());
          },
          reset: () => {
            return true;
          }
        };
      } else {
        this.widgetId = window.grecaptcha.render(element, parameters);
      }
      this._onRenderCallback();
    }
    _onRenderCallback() {
      this._invokeCallback('onRender', this);
    }
    _onSuccessCallback(response) {
      this._invokeCallback('onSuccess', response);
    }
    _onExpiredCallback() {
      this._invokeCallback('onExpired');
    }
    _onErrorCallback(error) {
      this._invokeCallback('onError', error);
    }
    _invokeCallback(callback, value) {
      const _callback = this.args[callback];
      if ((0, _utils.isPresent)(_callback) && typeof _callback === 'function') {
        _callback(value);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "config", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "config"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "componentOptions", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "componentOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "options", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_initialize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_initialize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_destroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_destroy"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GRecaptchaComponent);
});